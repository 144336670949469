export const ready = new Promise(function (resolve) {
  [
    '/static/editor/css/editormd.min.css',
    '/static/editor/css/editormd.preview.min.css',
    '/static/editor/css/editormd.logo.min.css'
  ].forEach(css => {
    let link = document.createElement('link')
    link.href = css
    link.type = 'text/css'
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  })
  let res = [
    '/static/js/zepto.min.js',
    '/static/editor/editormd.min.js',
    '/static/editor/lib/marked.min.js',
    '/static/editor/lib/prettify.min.js'
  ].map(src => {
    return new Promise((r) => {
      let script = document.createElement('script')
      script.src = src
      script.onload = r
      document.body.appendChild(script)
    })
  })
  Promise.all(res).then(resolve)
})

export function compressImages (file, width = 800, size = 0.5) {
  return new Promise((resolve) => {
    let worker = new Worker('/static/worker/images.load.js')
    worker.postMessage(file)
    worker.onmessage = function (e) {
      let image = new Image()
      image.src = e.data
      image.onload = function () {
        const WIDTH = width
        const SIZE = size * 1024 * 1024 // 超过 0.5M 就会压缩

        let scale = this.width > WIDTH ? (this.width / this.height) : false
        let quality = file.size > SIZE ? (SIZE / file.size) : false
        
        let canvas = document.createElement('canvas')
        canvas.width = scale !== false ? WIDTH : this.width
        canvas.height = scale !== false ? parseInt(canvas.width / scale) : this.height

        let context = canvas.getContext('2d')
        context.drawImage(this, 0, 0, canvas.width, canvas.height)
        let res = canvas.toDataURL('image/jpeg', quality !== false ? quality : 1)
        console.log(file.name, '原始大小: ', file.size / 1024 / 1024, 'M', '限定大小: ', size, 'M', '压缩比: ', quality, res)
        resolve({name: file.name, data: res})
      }

      worker.terminate()
      worker = null
    }
  })
}