export function getExtension (name) {
  return name ? (name.split('.').pop()).toLowerCase() : null
}

export function isImg (name) {
  return ['png', 'jpg', 'jpeg', 'gif'].includes(getExtension(name))
}
export function isText (name) {
  return ['md', 'txt'].includes(getExtension(name))
}

export function isExcel (name) {
  return ['xls', 'xlsx'].includes(getExtension(name))
}

export function isPdf (name) {
  return getExtension(name) === 'pdf'
}