<style scoped>
  .mdeditor {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
</style>

<template>
  <div :id="id" class="mdeditor">
    <textarea :value="value" style="display:none;"></textarea>
    <fm-modal v-model="status.uploadImg" :closable="false" title="插入图片">
      <input type="file" ref="imgfiles" multiple accept="image/*" />
      <div slot="footer" style="display:flex;justify-content: flex-end;">
        <fm-btn
          v-loadingx="loading.insertImg"
          @click="status.uploadImg = false">
          取消
        </fm-btn>
        <fm-btn
          v-loadingx="loading.insertImg"
          style="margin-left: 1rem;"
          @click="insertImages">
          {{loading.insertImg ? '处理中' : '插入'}}
        </fm-btn>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import { ready, compressImages } from './lib'

export default {
  components: {},
  data () {
    let resolve = null
    let promise = new Promise(r => resolve = r)
    return {
      instance: null,
      resolve: resolve,
      mounted: promise,
      width: 0,
      height: 0,
      status: {
        uploadImg: false
      },
      loading: {
        insertImg: false
      }
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: { type: String, default: '' },
    type: {
      type: String, default: 'edit', validator: function(type) {
        return ['edit', 'view'].includes(type)
      }
    },
    options: {
      type: Object, default () {
        return {}
      }
    }
  },
  watch: {
    type: {
      immediate: true,
      handler () {
        this.load()
      }
    }
  },
  computed: {
    id () {
      return (this.type === 'edit' ? 'editormd-' : 'markdown-to-html-') + this._uid
    },
    configs () {
      // { success: 0, message: '', url: '上传成功时才返回' }
      return Object.assign({
        width: this.width,
        height: this.height,
        imageUpload: true,
        imageFormats: ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'webp'],
        imageUploadURL: '',
        crossDomainUpload: false,
        path: '/static/editor/lib/',
        codeFold: true,
        watch: false,
        saveHTMLToTextarea: true,
        searchReplace: true,
        htmlDecode: 'style,script,iframe|on*',
        toolbarIcons: function () {
          return window.editormd.toolbarModes['simple'].concat('imagex')
        },
        toolbarIconsClass: {
          imagex: 'fa-picture-o'
        },
        toolbarHandlers: {
          imagex: this.openUploadImg
        },
        lang : {
          toolbar : {
            imagex : "插入图片"
          }
        },
        emoji: true,
        // onchange: (v) => {
        //   this.$emit('input', this.instance.getMarkdown())
        // },
        onload: () => {
          this.instance.cm.on('change', () => {
            this.$emit('input', this.instance.getMarkdown())
          })
        }
      }, this.options)
    }
  },
  methods: {
    openUploadImg () {
      this.status.uploadImg = true
    },
    async insertImages () {
      this.loading.insertImg = true

      let tasks = []
      for (const file of this.$refs.imgfiles.files) {
        tasks.push(compressImages(file))
      }

      let res = await Promise.all(tasks)
      res.forEach(({data, name})=> {
        this.instance.insertValue('###### ' + name + ' ######' + "\n" + '![' + name + '](' + data + ')')
      })

      this.$refs.imgfiles.value = null
      this.loading.insertImg = false
      this.status.uploadImg = false
    },
    async onPaste (event) {
      const items = event.clipboardData.items
      if (items.length && items[0].type.indexOf('image') > -1) {
        event.preventDefault();
        let {data} = await compressImages(items[0].getAsFile())
        this.instance.insertValue('###### 屏幕截图 ######' + "\n" + '![屏幕截图](' + data + ')')
      }
    },
    async load () {
      await ready
      await this.mounted
      this.$nextTick(() => {
        this.width = this.$el.clientWidth
        this.height = this.$el.clientHeight
        try {
          if (this.type === 'edit') {
            this.instance = window.editormd(this.id, this.configs)
          } else {
            this.instance = window.editormd.markdownToHTML(this.id, this.configs)
          }
        } catch (error) {
          this.$notice.error({title: '文件解析失败', desc: error.message})
        }
      })
    }
  },
  mounted () {
    this.$nextTick(this.resolve)
    document.addEventListener('paste', this.onPaste)
  },
  destroyed () {
    document.removeEventListener('paste', this.onPaste) 
  }
}
</script>
